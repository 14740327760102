import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-35bf0481"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tinymce-boxz"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Editor = _resolveComponent("Editor");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Editor, {
    modelValue: $setup.content,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.content = $event),
    "api-key": _ctx.apiKey,
    init: _ctx.init
  }, null, 8, ["modelValue", "api-key", "init"])]);
}